<template>
  <div class="advantages">
    <div class="title">{{ $t('home.product_advantages') }}</div>
    <div class="content">
      <img src="../../assets/img/new_home/exclusive.svg" alt="">
      <div>
        <div>{{ $t('home.exclusive_strategy_model') }}</div>
        <div>{{ $t('home.exclusive_strategy_model_tip') }}</div>
      </div>
    </div>

    <div class="content">
      <img src="../../assets/img/new_home/automatic.svg" alt="">
      <div>
        <div>{{ $t('home.fully_automatic_unattended') }}</div>
        <div>{{ $t('home.fully_automatic_unattended_tip') }}</div>
      </div>
    </div>

    <div class="content">
      <img src="../../assets/img/new_home/security.svg" alt="">
      <div>
        <div>{{ $t('home.financial_security') }}</div>
        <div>{{ $t('home.financial_security_tip') }}</div>
      </div>
    </div>

    <div class="content">
      <img src="../../assets/img/new_home/update.svg" alt="">
      <div>
        <div>{{ $t('home.regularly_update_policy') }}</div>
        <div>{{ $t('home.regularly_update_policy_tip') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home_advantages"
}
</script>

<style scoped>
.advantages{
  margin-top: 70px;
}
.title{
  font-size: 28px;
  font-weight: 500;
  color: #000613;
  margin-bottom: 0px;
}
.content>img{
  width: 48px;
  height: 49px;
}
.content>div>div:first-child{
  font-size: 20px;
  font-weight: 500;
  color: #000613;
  margin: 24px 0 16px 0;
}
.content>div>div:nth-child(2){
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
}
.content{
  width: 22%;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin-top: 3%;
}
.content:nth-child(3),.content:nth-child(4){
  margin:  3%;
}
@media screen and (max-width: 1100px){
  .content{
    width: 24%;
    margin-top: 3%;
  }
  .content:nth-child(3),.content:nth-child(4){
    margin: 3% 1%;
  }
}
@media screen and (max-width: 900px){
  .advantages{
    margin-top: 48px;
  }
  .title{
    font-size: 20px;
    font-weight: 600;
    color: #000613;
    margin-bottom: 32px;
  }
  .content{
    width: 40%;
  }
  .content:nth-child(3),.content:nth-child(5){
    margin-left: 10%;
  }
}
@media screen and (max-width: 600px){
  .content{
    width: 100%;
    text-align: left;
    margin-bottom: 20px !important;
  }
  .content:nth-child(3),.content:nth-child(5){
    margin: 0;
  }
  .content>img{
    width: 12%;
    margin-right: 4%;
    vertical-align: top;
  }
  .content>div{
    display: inline-block;
    width: 84%;
    vertical-align: top;
  }
  .content>div>div:first-child{
    font-size: 16px;
    font-weight: 600;
    color: #444444;
    margin: 0 0 6px 0;

  }
}
</style>
