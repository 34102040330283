<template>
  <div>
    <div v-if="!isMobile" class="" style="width: 100%; max-width: unset; height: 458px;background: #F7F8FA;display: flex;align-items: center;justify-content: center;padding: unset">
      <div style="width: 1200px;">
        <div class="title" style="text-align: center;">60秒开始您的数字货币之旅</div>
        <div style="width: 988px;margin: 0 auto; height: unset; position: relative">
          <div style="display: flex; justify-content: space-between; width: 100%;">
            <span style="width: 48px;height: 48px;background: #AB54DB;border-radius: 50%; text-align: center;line-height: 48px;font-size: 24px;color: #FFFFFF">1</span>
            <span style="width: 48px;height: 48px;background: #AB54DB;border-radius: 50%; text-align: center;line-height: 48px;font-size: 24px;color: #FFFFFF">2</span>
            <span style="width: 48px;height: 48px;background: #AB54DB;border-radius: 50%; text-align: center;line-height: 48px;font-size: 24px;color: #FFFFFF">3</span>
          </div>
          <div style="position: absolute;width: 100%;height: 1px;background: rgba(115, 126, 141, 0.16); top: 50%; transform: translate(0,-50%)"></div>
        </div>

        <div style="width: 100%; display: flex; justify-content: space-between; margin-top: 16px">
          <div style="text-align: center; width: 260px;">
            <span style="font-size: 24px; font-weight: 500; width: 260px; text-align: center; word-break: break-word">选择一个交易所并连接</span>
            <div style="margin-top: 20px;cursor: pointer" @click="$router.push('/user/safe')"><span style="font-size: 16px; font-weight: 500">GO</span><i class="el-icon-right" style="font-size: 16px; font-weight: 500"></i></div>
          </div>
          <div style="text-align: center; width: 260px;">
            <span style="font-size: 24px; font-weight: 500; width: 260px; text-align: center">充值燃料</span>
            <div style="margin-top: 20px;cursor: pointer" @click="$router.push('user/property')"><span style="font-size: 16px; font-weight: 500">GO</span><i class="el-icon-right" style="font-size: 16px; font-weight: 500"></i></div>
          </div>
          <div style="text-align: center; width: 260px;">
            <span style="font-size: 24px; font-weight: 500; width: 260px; text-align: center">开启机器人</span>
            <div style="margin-top: 20px;cursor: pointer" @click="$router.push('/robot')"><span style="font-size: 16px; font-weight: 500">GO</span><i class="el-icon-right" style="font-size: 16px; font-weight: 500"></i></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isMobile" style="height: 685px; width: 100%;display: flex; flex-direction: column; justify-content: center">
      <div class="title" style="font-weight: 500;font-size: 24px;line-height: 36px;text-align: center;color: #191919;">60秒开始您的数字货币之旅</div>
      <div style="width: 100%; display: flex; justify-content: space-between; margin-top: 30px;flex-direction: column">
        <div style="text-align: center">
          <span style="display: block; width: 48px;height: 48px;background: #AB54DB;border-radius: 50%; text-align: center;line-height: 48px;font-size: 24px;color: #FFFFFF;margin: 0 auto">1</span>
          <span style="display: block; font-size: 16px; font-weight: 500; width: 260px; text-align: center; word-break: break-word;margin: 15px auto 0">选择一个交易所并连接</span>
          <div style="margin-top: 20px;cursor: pointer" @click="$router.push('/user/safe')"><span style="font-size: 16px; font-weight: 500">GO</span><i class="el-icon-right" style="font-size: 16px; font-weight: 500"></i></div>
        </div>
        <div style="text-align: center;margin-top: 30px">
          <span style="display: block;width: 48px;height: 48px;background: #AB54DB;border-radius: 50%; text-align: center;line-height: 48px;font-size: 24px;color: #FFFFFF;margin: 0 auto">2</span>
          <span style="display: block;font-size: 16px; font-weight: 500; width: 260px; text-align: center;margin: 15px auto 0">充值燃料</span>
          <div style="margin-top: 20px;cursor: pointer" @click="$router.push('user/property')"><span style="font-size: 16px; font-weight: 500">GO</span><i class="el-icon-right" style="font-size: 16px; font-weight: 500"></i></div>
        </div>
        <div style="text-align: center;margin-top: 30px">
          <span style="display: block;width: 48px;height: 48px;background: #AB54DB;border-radius: 50%; text-align: center;line-height: 48px;font-size: 24px;color: #FFFFFF;margin: 0 auto">3</span>
          <span style="display: block;font-size: 16px; font-weight: 500; width: 260px; text-align: center;margin: 15px auto 0">开启机器人</span>
          <div style="margin-top: 20px;cursor: pointer" @click="$router.push('/robot')"><span style="font-size: 16px; font-weight: 500">GO</span><i class="el-icon-right" style="font-size: 16px; font-weight: 500"></i></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Home_process",
  data(){
    return{
      isMobile: false
    }
  },

  created() {
    // this.$i18n.locale='en'
    if (this.$util._isMobile()){
      this.isMobile = true
    }
  },
}
</script>

<style scoped>
.process{
  /*padding: 30px 0 70px 0;*/
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.title{
  font-size: 40px;
  font-weight: 700;
  color: #000613;
  margin-bottom: 40px;
}
.process>div:nth-child(2){
  width: 21.6%;
  height: 194px;
  display: inline-flex;
}
.process>div:nth-child(2)>img{
  width: 100%;
}
.contents{
  width: 73.6%;
  margin-left: 4.8%;
  display: inline-block;
  vertical-align: top;
}
.content>span{
  font-size: 16px;
  font-weight: 400;
  color: #000613;
  margin-left: 16px;
}
.content{
  padding: 20px 0;
  border-bottom: #EAECEF solid 1px;
  position: relative;
  display: flex;
  align-items: center;
}
.content>div{
  position: absolute;
  right: 0;
  top: 20px;
  width: 80px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid #AB54DB;
  font-size: 14px;
  font-weight: 400;
  color: #AB54DB;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}
.content:first-child{
  border-top: #EAECEF solid 1px;
}

@media screen and (max-width: 900px){
  .process{
    padding: 24px 0;
  }
  .title{
    font-size: 20px;
    font-weight: 600;
    color: #000613;
    margin-bottom: 14px;
  }
  .process>div:nth-child(2){
    display: none;
  }
  .contents{
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (max-width: 500px){
  .content>div{
    width: auto;
    height: 24px;
    border: none;
  }
  .content>div>span{
    display: none;
  }
}

/*处理鼠标悬浮*/
.content>div:hover{
  cursor: pointer;
  background: #AB54DB;
  color: #FFFFFF;
}
</style>
