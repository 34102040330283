<template>
  <div class="bg" style="">
    <div v-if="!isMobile" style="width: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center; height: 624px;">
      <span style="display:block;font-weight: 500;font-size: 48px;color: #191919;">我们的合作伙伴</span>
      <div style="height: 1px;background: rgba(0,0,0,0.3);width: 100%;margin: 40px 0"></div>
      <div style="width: 1200px;height: 130px;">
        <img style="display: inline-block; width: 88px; height: 88px;vertical-align: top" :src="src">
        <div style=" width: 1000px;margin-left: 50px;display: inline-block">
          <span style="display: inline-block; font-weight: 500;font-size: 14px;color: #293D51;vertical-align: middle">{{content_en}}</span>
          <span style="display: inline-block; font-weight: 500;font-size: 14px;color: #293D51;vertical-align: middle; margin-top: 5px">{{content}}</span>
          <div v-if="num == 2" style="margin-top: 10px">
            <a style="display: inline-block;color: #969596; font-size: 14px" target="_blank" :href="$registered_address.okx_address()">{{$t('footer.ul_li3')}}：<span style="">({{ $registered_address.okx_address() }})</span></a>
            <span style="display: inline-block;cursor: pointer;margin-left: 10px; color: #AB54DB;border-bottom: #AB54DB 1px solid;line-height: 14px; font-size: 14px" @click="copy()">{{ $t('alter.copy') }}</span>
          </div>
        </div>
      </div>
      <div style="display: flex;">
        <div style="text-align: center; cursor: pointer" @click="change(1)">
          <img style="width: 96px; height: 106px;" src="../../assets/img/home/bian_icon.png">
          <span style="display: block; font-weight: 500;font-size: 24px;line-height: 36px;color: #191919;">Binance</span>
        </div>
        <div style="text-align: center; margin-left: 236px; cursor: pointer" @click="change(2)">
          <img style="width: 96px; height: 106px;" src="../../assets/img/home/okx_icon.png">
          <span style="display: block; font-weight: 500;font-size: 24px;line-height: 36px;color: #191919;">Okx</span>
        </div>
      </div>
    </div>
    <div v-if="isMobile" style="width: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center; height: 490px;">
      <span style="display:block;font-weight: 500;font-size: 24px;color: #191919;">我们的合作伙伴</span>
      <div style="height: 1px;background: rgba(0,0,0,0.2);width: 90%;margin: 40px 0 10px"></div>
      <div style="width: 90%; height: 163px; display: flex; justify-content: center">
        <img style="display: inline-block; width: 56px; height: 56px;vertical-align: top" :src="src">
        <div style="display: inline-block; width: 78%;margin-left: 12px;">
          <span style="display: inline-block; font-weight: 500;font-size: 14px;color: #293D51;vertical-align: middle">{{content}}</span>
          <div v-if="num == 2" style="margin-top: 10px;">
            <a style="display: inline-block;color: #969596; font-size: 14px;word-break: break-word;" target="_blank" :href="$registered_address.okx_address()">{{$t('footer.ul_li3')}}：<span style="">({{ $registered_address.okx_address() }})</span></a>
            <span style="display: block;cursor: pointer;margin-top: 5px;color: #AB54DB;border-bottom: #AB54DB 1px solid;line-height: 14px; font-size: 14px; float: right" @click="copy()">{{ $t('alter.copy') }}</span>
          </div>
        </div>

      </div>

      <div style="display: flex; margin-top: 30px; width: 60%; justify-content: space-between">
        <div style="text-align: center; cursor: pointer" @click="change(1)">
          <img style="width: 65px; height: 73px;" src="../../assets/img/home/bian_icon.png">
          <span style="display: block; font-weight: 500;font-size: 16px;line-height: 36px;color: #191919;">Binance</span>
        </div>
        <div style="text-align: center; cursor: pointer" @click="change(2)">
          <img style="width: 65px; height: 73px;" src="../../assets/img/home/okx_icon.png">
          <span style="display: block; font-weight: 500;font-size: 16px;line-height: 36px;color: #191919;">Okx</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home_partners",
  data(){
    return{
      isMobile: false,
      src: require('../../assets/img/home/bian_logo.png'),
      content_en: 'The world\'s largest virtual currency digital asset exchange service platform official website.\n' +
          'Binance is a trading platform focusing on blockchain assets created by a group of digital asset enthusiasts, providing users with safer and more convenient blockchain asset exchange services, aggregating global high-quality blockchain assets, and dedicated to building a world-class blockchain asset trading platform.',
      content: '全球最大的虚拟货币数字资产交换服务平台官方网站。\n' +
          'Binance是一个专注于区块链资产的交易平台，由一群数字资产爱好者创建，为用户提供更安全、更便捷的区块链资产交换服务，聚合全球优质区块链资产，并致力于构建世界级的区块链资产交易平台。',
      num: 1
    }
  },

  created() {
    // this.$i18n.locale='en'
    if (this.$util._isMobile()){
      this.isMobile = true
    }
  },

  methods: {
    change(num){
      this.num = num
      if (num == 1){
        this.src = require('../../assets/img/home/bian_logo.png')
        this.content = '全球最大的虚拟货币数字资产交换服务平台官方网站。\n' +
            'Binance是一个专注于区块链资产的交易平台，由一群数字资产爱好者创建，为用户提供更安全、更便捷的区块链资产交换服务，聚合全球优质区块链资产，并致力于构建世界级的区块链资产交易平台。'
        this.content_en = 'The world\'s largest virtual currency digital asset exchange service platform official website.\n' +
            'Binance is a trading platform focusing on blockchain assets created by a group of digital asset enthusiasts, providing users with safer and more convenient blockchain asset exchange services, aggregating global high-quality blockchain assets, and dedicated to building a world-class blockchain asset trading platform.'
      } else {
        this.src = require('../../assets/img/home/okx_logo.png')
        this.content = 'OKX是全球著名的数字资产交易平台之一，主要为全球用户提供比特币、莱特币和以太币等数字资产的货币和衍生品交易服务。'
        this.content_en = 'OKX is one of the world\'s famous digital asset trading platforms, mainly providing global users with currency and derivatives trading services for digital assets such as Bitcoin, Litecoin and Ethereum.'
      }
    },
    copy() {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.$registered_address.okx_address());
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    },
  }
}

</script>

<style scoped>
@media screen and (max-width: 900px) {
  .bg {
    /*width: 1200px;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center*/
  }
}

@media screen and (min-width: 900px) {
  .bg {
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

</style>
