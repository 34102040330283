<template>
  <div class="advice" style="text-align: center">
    <div class="title">{{ $t('home.investment_advice') }}</div>
    <div style="display: flex; justify-content: space-between; align-items: center">
      <div style="text-align: left">
        <span style="display: block; font-weight: 500;font-size: 36px;line-height: 54px;color: #F9FAFA;">加密货币开创全新时代</span>
        <span style="display: block; font-weight: 500;font-size: 24px;line-height: 54px;color: #F9FAFA;">U plus 助您創造、增值及保管財富</span>
        <div style="background: #AB54DB;border-radius: 30px;font-weight: 500;font-size: 36px;text-align: center;color: #FFFFFF;margin-top: 30px;width: 150px;height: 60px;line-height: 60px;cursor: pointer" @click="$router.push('/robot')">Go></div>
      </div>
      <div style="display: flex; align-items: center">
        <div style="display: inline-block; width: 762px;height: 337px;background: #F9FAFA;border-radius: 20px;text-align: center">
          <el-carousel height="337" :interval="5000" @change="index" ref="carousel" indicator-position="none" arrow="never">
            <el-carousel-item v-for="(item, index) in contentList" :key="index">
              <div style="height: 337px;position: relative;display: flex; flex-direction: column; align-items: center; justify-content: center">
                <img style="width: 160px; height: 160px;" :src="item.img">
                <span style="display: block; font-weight: 500;font-size: 24px;line-height: 36px;text-align: center;color: #212131;margin-top: 10px">{{ item.info1 }}</span>
                <span style="display: block; font-weight: 500;font-size: 24px;line-height: 36px;text-align: center;color: rgba(25, 25, 25, 0.6);margin-top: 10px">{{ item.info2 }}</span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="display: inline-flex; flex-direction: column; justify-content: space-around;height: 300px;">
          <span :class="num == 1?'index_num_click':'index_num'" style="" @mouseover="changeNum(0)" @click="changeNum(0)">1</span>
          <span :class="num == 2?'index_num_click':'index_num'" style="" @mouseover="changeNum(1)" @click="changeNum(1)">2</span>
          <span :class="num == 3?'index_num_click':'index_num'" style="" @mouseover="changeNum(2)" @click="changeNum(2)">3</span>
          <span :class="num == 4?'index_num_click':'index_num'" style="" @mouseover="changeNum(3)" @click="changeNum(3)">4</span>
          <span :class="num == 5?'index_num_click':'index_num'" style="" @mouseover="changeNum(4)" @click="changeNum(4)">5</span>
          <span :class="num == 6?'index_num_click':'index_num'" style="" @mouseover="changeNum(5)" @click="changeNum(5)">6</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home_advice_lg",
  data(){
    return{
      contentList: [{
        img: require('../../../assets/img/home/three_one.png'),
        info1:'顶级性能，极速交易',
        info2:'交易所技术驱动，超低延迟，极速撮合',
      },{
        img: require('../../../assets/img/home/three_two.png'),
        info1:'资产保障，极致安全',
        info2:'U Plus 符合 CCSS 标准，保障资产及数据安全',
      },{
        img: require('../../../assets/img/home/three_three.png'),
        info1:'高流动，低点差',
        info2:'U Plus 合约杠杆交易，超高效率，最小滑点',
      },{
        img: require('../../../assets/img/home/three_four.png'),
        info1:'全天候服务',
        info2:'保证系统正常运行，提供全天候客户服务',
      },{
        img: require('../../../assets/img/home/three_five.png'),
        info1:'便捷易用',
        info2:'提供多种订单系统及交易工具，风险可控',
      },{
        img: require('../../../assets/img/home/three_six.png'),
        info1:'随时随地，安心交易',
        info2:'U Plus 平台提供PC网页版、手机 Android、iOS App应用程序',
      }],
      num: 1
    }
  },

  created() {

  },

  mounted() {

  },

  methods:{
    index(e){
      this.num = e+1
    },

    changeNum(num){
      this.$refs.carousel.setActiveItem(num)
    }
  }
}
</script>

<style scoped>

@media screen and (min-width: 900px) {
  .index_num_click{
    display: block;
    width: 45px;
    height: 35px;
    background: #816399;
    border-radius: 0px 28px 28px 0px;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    line-height: 36px;
    cursor: pointer
  }
  .index_num{
    display: block;
    width: 45px;
    height: 35px;
    background: rgba(249, 250, 250, 0.7);
    border-radius: 0px 28px 28px 0px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: rgba(33, 33, 49, 0.6);
    line-height: 36px;
    cursor: pointer
  }
}


th,td{
  border-right: #EAECEF solid 1px;
  border-bottom: #EAECEF solid 1px;
}
.advice{
  /*padding: 50px 0 50px 0;*/
  height: 657px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title{
  font-size: 48px;
  font-weight: 700;
  color: #F9FAFA;
  margin-bottom: 40px;
}
tr>th:nth-child(7),tr>td:nth-child(7){
  border-right: none;
}
tr>td:first-child{
  min-width: 100px;
}
tr>td:nth-child(2){
  padding: 21px 0 21px 16px;
}

tr>td:nth-child(3){
  padding-left: 18px;
  min-width: 110px;
}
tr>td:nth-child(3)>img:first-child{
  margin-right: 16px;
}
tr>td:nth-child(n+4){
  padding-left: 16px;
  padding-right: 16px;
}
td:nth-child(7)>div{
  width: 40px;
  height: 40px;
  background: #AB54DB;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
}
th{
  font-size: 14px;
  font-weight: 500;
  color: #000613;
}
td{
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
}
/*操作th*/
th{
  text-align: left;
}
tr>th:nth-child(n+2){
  padding-left: 16px;
}

/*鼠标悬浮操作*/
tr>td:nth-child(7):hover,.content>div>span:hover{
  cursor: pointer;
  opacity: 0.9;
}
</style>
