import request from "../utils/request";

/**
 * 活动详情
 * @param {*} id
 */
export const getActivityById_Other = (id, requestTimes) =>request({
    method: 'GET',
    url: '/other/getActivityById',
    params: {id, requestTimes}
})
