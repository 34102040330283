<template>
  <div>
    <div v-if="!isMobile" class="product" style="height: 558px; max-width: 1200px; display: flex; flex-direction: column; justify-content: center; margin: 0 auto; text-align: center">
      <div class="product-title">{{ $t('home.product_description') }}</div>
      <div class="icons" style="display: flex; justify-content: space-between">
        <div class="product_bg" style="position: relative; cursor: pointer" @click="$router.push('../../robot')">
          <img style="width: 151px; height: 94px;" src="../../assets/img/home/hedge_icon.png">
          <span style="display:block; font-weight: 700;font-size: 24px;line-height: 36px;color: #212131;margin-top: 20px;">智能合约交易</span>
          <span style="display: block;font-size: 16px;line-height: 24px;color: rgba(33, 33, 49, 0.6);margin-top: 6px;width: 90%;height: 48px;">多空对冲震荡套利，风险可控</span>
          <div style="position: absolute; bottom: 30px">
            <span style="color: #2CC197 !important;font-size: 14px;padding: 1px 5px; background: rgba(44, 193, 151, 0.18)">年800%+</span>
            <span style="color: #DC4D4D !important; background: rgba(220, 77, 77, 0.2); margin-left: 5px; padding: 1px 5px; font-size: 14px">HOT</span>
          </div>
        </div>
        <div class="product_bg" style="position: relative; cursor: pointer" @click="$router.push('../../robot')">
          <img style="width: 151px; height: 94px;" src="../../assets/img/home/bb_icon.png">
          <span style="display:block; font-weight: 700;font-size: 24px;line-height: 36px;color: #212131;margin-top: 20px;">现货全自动交易</span>
          <span style="display: block;font-size: 16px;line-height: 24px;color: rgba(33, 33, 49, 0.6);margin-top: 6px;width: 90%;height: 48px;">双币种交易套利，适合横盘屯币</span>
          <div style="position: absolute; bottom: 30px">
            <span style="color: #2CC197 !important;font-size: 14px;padding: 1px 5px; background: rgba(44, 193, 151, 0.18)">年100%+</span>
            <span style="color: #DC4D4D !important; background: rgba(220, 77, 77, 0.2); margin-left: 5px; padding: 1px 5px; font-size: 14px">NEW</span>
          </div>
        </div>
        <div class="product_bg" style="cursor: pointer" @click="$router.push('../../robot')">
          <img style="width: 151px; height: 94px;" src="../../assets/img/home/grid_icon.png">
          <span style="display:block; font-weight: 700;font-size: 24px;line-height: 36px;color: #212131;margin-top: 20px;">现货网格交易</span>
          <span style="display: block;font-size: 16px;line-height: 24px;color: rgba(33, 33, 49, 0.6);margin-top: 6px;width: 90%;height: 48px;">震荡行情中自动低买高卖，稳定套利</span>
        </div>
        <div class="product_bg" style="cursor: pointer" @click="$router.push('../../robot')">
          <img style="width: 151px; height: 94px;" src="../../assets/img/home/martin_icon.png">
          <span style="display:block; font-weight: 700;font-size: 24px;line-height: 36px;color: #212131;margin-top: 20px;">现货马丁交易</span>
          <span style="display: block;font-size: 16px;line-height: 24px;color: rgba(33, 33, 49, 0.6);margin-top: 6px;width: 90%;height: 48px;">单边加倍押注降低成本，回调止盈</span>
        </div>
      </div>
    </div>

    <div v-if="isMobile" class="product" style="height: 600px; display: flex; flex-direction: column; justify-content: center; margin: 0 auto; text-align: center; padding: unset">
      <div class="product-title" style="">4款免费交易机器人</div>
      <div class="icons" style="">
        <div style="width: 85%; margin: 0 auto; display: flex; justify-content: space-between">
          <div class="" style="width: 149px;height: 171px;background: #EAEBED;border-radius: 6px;text-align: center;display: flex; flex-direction: column;align-items: center;justify-content: center; position: relative;" @click="$router.push('../../robot')">
            <div style="position: absolute; top: 10px; right: 10px">
              <span style="color: #2CC197 !important;font-size: 12px;padding: 1px 2px; background: rgba(44, 193, 151, 0.18)">年800%+</span>
<!--              <span style="color: #DC4D4D !important; background: rgba(220, 77, 77, 0.2); margin-left: 5px; padding: 1px 5px; font-size: 12px">HOT</span>-->
            </div>
            <img style="width: 87px; height: 54px;" src="../../assets/img/home/hedge_icon.png">
            <span style="display:block; font-weight: 500;font-size: 16px;line-height: 24px;text-align: center;color: #191919;margin-top: 10px;">智能合约交易</span>
            <span style="display: block;font-weight: 400;font-size: 14px;line-height: 21px;text-align: center;color: rgba(25, 25, 25, 0.6);height: 48px;width: 90%;margin: 0 auto">多空对冲震荡套利，风险可控</span>
          </div>
          <div class="" style="width: 149px;height: 171px;background: #EAEBED;border-radius: 6px;text-align: center;display: flex; flex-direction: column;align-items: center;justify-content: center; position: relative" @click="$router.push('../../robot')">
            <div style="position: absolute; top: 10px; right: 10px">
              <span style="color: #2CC197 !important;font-size: 12px;padding: 1px 5px; background: rgba(44, 193, 151, 0.18)">年100%+</span>
<!--              <span style="color: #DC4D4D !important; background: rgba(220, 77, 77, 0.2); margin-left: 5px; padding: 1px 5px; font-size: 14px">NEW</span>-->
            </div>
            <img style="width: 87px; height: 54px;" src="../../assets/img/home/bb_icon.png">
            <span style="display:block; font-weight: 500;font-size: 16px;line-height: 24px;text-align: center;color: #191919;margin-top: 10px;">现货全自动交易</span>
            <span style="display: block;font-weight: 400;font-size: 14px;line-height: 21px;text-align: center;color: rgba(25, 25, 25, 0.6);height: 48px;width: 90%;">双币种交易套利，适合横盘屯币</span>
          </div>
        </div>
        <div style="width: 85%; margin: 0 auto; display: flex; justify-content: space-between;margin-top: 30px">
          <div class="" style="width: 149px;height: 171px;background: #EAEBED;border-radius: 6px;text-align: center;display: flex; flex-direction: column;align-items: center;justify-content: center" @click="$router.push('../../robot')">
            <img style="width: 87px; height: 54px;" src="../../assets/img/home/grid_icon.png">
            <span style="display:block; font-weight: 500;font-size: 16px;line-height: 24px;text-align: center;color: #191919;margin-top: 10px;">现货网格交易</span>
            <span style="display: block;font-weight: 400;font-size: 14px;line-height: 21px;text-align: center;color: rgba(25, 25, 25, 0.6);height: 48px;width: 90%;margin: 0 auto">震荡行情中自动低买高卖，稳定套利</span>
          </div>
          <div class="" style="width: 149px;height: 171px;background: #EAEBED;border-radius: 6px;text-align: center;display: flex; flex-direction: column;align-items: center;justify-content: center" @click="$router.push('../../robot')">
            <img style="width: 87px; height: 54px;" src="../../assets/img/home/martin_icon.png">
            <span style="display:block; font-weight: 500;font-size: 16px;line-height: 24px;text-align: center;color: #191919;margin-top: 10px;">现货马丁交易</span>
            <span style="display: block;font-weight: 400;font-size: 14px;line-height: 21px;text-align: center;color: rgba(25, 25, 25, 0.6);height: 48px;width: 90%;">单边加倍押注降低成本，回调止盈</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home_product",
  data(){
    return{
      isMobile: false
    }
  },
  created() {
    if (this.$util._isMobile()){
      this.isMobile = true
    }
  },
}

</script>

<style scoped>
/**
product
 */
.product{
  /*padding-top: 70px;*/
  /*padding-bottom: 60px;*/
}
.product .product-title{
  font-size: 48px;
  font-weight: 700;
  color: #1C1C2E;
  margin-bottom: 60px;
}
.product .icons .icon{
  width: 30%;
}
.product .icons>div:first-child>div:first-child,.product .icons>div:nth-child(2)>div:first-child,.product .icons>div:nth-child(3)>div:first-child{
  background: linear-gradient(180deg, #3F3636 0%, #262121 100%)
}
.product .icons>div:nth-child(4)>div:first-child,.product .icons>div:nth-child(5)>div:first-child,.product .icons>div:nth-child(6)>div:first-child{
  background: linear-gradient(180deg, #0B3184 0%, #001448 100%);
}
.product .icons .icon>div:first-child>div{
  width: 60%;
  display: inline-block;
  vertical-align: top;
  padding-left: 7%;
  box-sizing: border-box;
}
.product .icons .icon>div:first-child>img{
  width: 40%;
  display: inline-block;
}
.product .icons .icon>div:first-child>div>div:first-child{
  font-size: 20px;
  font-weight: bold;
  color: #E3B936;
  margin: 31px 0 21px 0;
}
.product .icons .icon>div:first-child>div>div:nth-child(2){
  font-size: 14px;
  font-weight: 400;
  color: #D5C8A8;
}
.product .icons .icon>div:nth-child(2){
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  margin: 24px 0 16px 0;
}
.product .icons .icon>div:nth-child(2)>span{
  font-size: 14px;
  font-weight: 400;
  color: #000613;
}
.product .icon>div:nth-child(n+3)>span:first-child{
  font-size: 14px;
  font-weight: 400;
  color: #000613;
}
.product .icon>div:nth-child(n+3)>span:nth-child(2){
  vertical-align: middle;
}
.product .icon>div:nth-child(n+3)>span:nth-child(2)>img{
  width: 16px;
  height: 16px;
}
.product .icon>div:nth-child(4){
  margin: 16px 0;
}

/*布局*/
.icon{
  display: inline-block;
  vertical-align: top;
}
.icon:nth-child(2),.icon:nth-child(5){
  margin: 0 5%;
}

@media screen and (max-width: 1100px){
  .product .icons .icon>div:first-child>div>div:first-child{
    font-size: 16px;
    font-weight: bold;
    color: #E3B936;
    margin: 10% 0 10% 0;
  }
  .product .icons .icon>div:first-child>div>div:nth-child(2){
    font-size: 12px;
    font-weight: 400;
    color: #D5C8A8;
  }
}
@media screen and (max-width: 900px){
  .product .icons .icon{
    width: 32%;
  }
  .icon:nth-child(2),.icon:nth-child(5){
    margin: 0 2%;
  }
  .product .icons .icon>div:first-child>div>div:first-child{
    font-size: 14px;
    font-weight: bold;
    color: #E3B936;
    margin: 10% 0 10% 0;
  }
  .product .icons .icon>div:first-child>div>div:nth-child(2){
    font-size: 12px;
    font-weight: 400;
    color: #D5C8A8;
  }

  .product .icons .icon>div:nth-child(2){
    font-size: 12px;
    font-weight: 400;
    color: #72777E;
    margin: 24px 0 16px 0;
  }
  .product .icons .icon>div:nth-child(2)>span{
    font-size: 12px;
    font-weight: 400;
    color: #000613;
  }
  .product .icon>div:nth-child(n+3)>span:first-child{
    font-size: 12px;
    font-weight: 400;
    color: #000613;
  }
  .product .icon>div:nth-child(4){
    margin: 10px 0;
  }
  .product .icons .icon>div:nth-child(2){
    font-size: 12px;
    font-weight: 400;
    color: #72777E;
    margin: 16px 0 10px 0;
  }
  .product .icon>div:nth-child(n+3)>span:nth-child(2)>img{
    width: 12px;
    height: 12px;
  }

  .product{
    padding-top: 48px;
    padding-bottom: 24px;
  }
  .product .product-title{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #191919;
  }
}
@media screen  and (max-width: 722px){
  .product .icons .icon>div:first-child>div>div:first-child{
    font-size: 12px;
    font-weight: bold;
    color: #E3B936;
    margin: 10% 0 5% 0;
  }
}
@media screen and (min-width: 700px){
  .icon:nth-child(n+4){
    margin-top: 26px;
  }

  .product_bg{
    width: 257px;
    height: 296px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .product_bg:hover{
    background: #393948;
    color: #FFFFFF !important;
  }

  .product_bg:hover span{
    color: #FFFFFF !important;
  }

  .product_bg:hover span:nth-child(3){
    color: rgba(255, 255, 255, 0.7) !important;
  }
}
@media screen and (max-width: 700px){
  .product .icons .icon{
    width: 100%;
  }
  .icon:nth-child(2),.icon:nth-child(5){
    margin: 26px 0;
  }
  .icon:nth-child(4){
    margin-top: 26px;
  }
  .product .icons .icon>div:first-child>img{
    width: 30%;
    display: inline-block;
  }

  .product .icons .icon>div:first-child>div>div:first-child{
    font-size: 20px;
    font-weight: bold;
    color: #E3B936;
    margin: 31px 0 27px 0;
  }
  .product .icons .icon>div:first-child>div>div:nth-child(2){
    font-size: 14px;
    font-weight: 400;
    color: #D5C8A8;
  }
  .product .icons .icon>div:nth-child(2){
    font-size: 14px;
    font-weight: 400;
    color: #72777E;
    margin: 16px 0 6px 0;
  }
  .product .icons .icon>div:nth-child(2)>span{
    font-size: 14px;
    font-weight: 400;
    color: #000613;
  }
  .product .icon>div:nth-child(n+3)>span:first-child{
    font-size: 14px;
    font-weight: 400;
    color: #000613;
  }
  .product .icon>div:nth-child(4){
    margin: 6px 0;
  }

}
@media screen and (max-width: 560px){
  .product .icons .icon>div:first-child>div{
    width: 58%;
    display: inline-block;
    vertical-align: top;
    padding-left: 7%;
    box-sizing: border-box;
  }
  .product .icons .icon>div:first-child>img{
    width: 42%;
    display: inline-block;
  }
  .product .icons .icon>div:first-child>div>div:first-child{
    font-size: 20px;
    font-weight: bold;
    color: #E3B936;
    margin: 25px 0 15px 0;
  }
}
</style>
