<template>
  <div>
    <div v-if="!isMobile" style="width: 1200px; height: 854px; margin: 0 auto;display: flex; justify-content: space-between; align-items: center">
      <div>
        <img style="display: block; width: 360px; height: 356px;" src="../../assets/img/home/comment_1.png">
        <img style="display: block; width: 360px; height: 268px;margin-top: 30px" src="../../assets/img/home/comment_4.png">
      </div>
      <div>
        <img style="display: block; width: 360px; height: 220px;" src="../../assets/img/home/comment_2.png">
        <img style="display: block; width: 360px; height: 404px;margin-top: 30px" src="../../assets/img/home/comment_5.png">
      </div>
      <div>
        <img style="display: block; width: 360px; height: 485px;" src="../../assets/img/home/comment_3.png">
        <img style="display: block; width: 360px; height: 139px;margin-top: 30px" src="../../assets/img/home/comment_6.png">
      </div>
    </div>

    <div v-if="isMobile" style="width: 100%; height: 800px; margin: 0 auto;display: flex; flex-direction: column; justify-content: center">
      <el-carousel height="620px" :interval="5000" ref="carousel" indicator-position="outside" arrow="never">
        <el-carousel-item>
          <div style="text-align: center">
            <img style="display: block; width: 86%;margin: 0 auto" src="../../assets/img/home/comment_1.png">
            <img style="display: block; width: 86%;margin: 28px auto 0" src="../../assets/img/home/comment_4.png">
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <img style="display: block; width: 86%;margin: 0 auto" src="../../assets/img/home/comment_2.png">
            <img style="display: block; width: 86%;margin: 28px auto 0" src="../../assets/img/home/comment_5.png">
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <img style="display: block; width: 86%;margin: 0 auto" src="../../assets/img/home/comment_3.png">
            <img style="display: block; width: 86%;margin: 28px auto 0" src="../../assets/img/home/comment_6.png">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "home_person",
  data(){
    return{
      isMobile: false,
    }
  },

  created() {
    // this.$i18n.locale='en'
    if (this.$util._isMobile()){
      this.isMobile = true
    }
  },
}
</script>

<style scoped>
>>>.el-carousel__indicator.is-active button {
  display: inline-block;
  /* padding: 12px 4px; */
  padding: unset;
  width: 6px;
  height: 6px;
  background: #BF5FC1;
  border-radius: 50%;
  opacity: unset;
}
>>>.el-carousel__indicators--outside button {
  display: inline-block;
  /* padding: 12px 4px; */
  padding: unset;
  width: 6px;
  height: 6px;
  background: #97979D;
  border-radius: 50%;
  opacity: unset;
}

>>>.el-carousel__indicator--horizontal{
  padding: 0 4px;
}

>>>.el-carousel__indicators--outside button :nth-child(2n+1) {
  margin-left: 4px;
}

@media screen and (min-width: 375px){
  >>>.el-carousel__container{
    height: 600px !important;
  }
}

@media screen and (min-width: 390px){
  >>>.el-carousel__container{
    height: 620px !important;
  }
}

@media screen and (min-width: 428px) {
  >>>.el-carousel__container{
    height: 690px !important;
  }
}

</style>
